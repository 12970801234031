<template>
    <div class="page-table haberTanim mainDiv listPage" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.bultenler.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" type="text" icon="el-icon-refresh" style="margin-left: 15px !important"></el-button>
                </el-tooltip>

                <el-tooltip :content='"Blog Tanımla"' :open-delay="500" placement="bottom">
                    <el-button class="route-btn" v-on:click="routeBulten('Bülten Tanımla')" type="text" icon="el-icon-plus" style="margin-left: 15px !important"></el-button>
                </el-tooltip>
            </div>
        </div>
        <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" 
        :lazy="true"
        v-loading="loading || loadingState"
        :element-loading-text='loading ? $t("src.views.apps.bultenler.liste.listeLoading") : $t("src.views.apps.bultenler.liste.durumLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10">
                <el-col :lg="16" :md="16" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"  text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.bultenler.liste.yayinda")}}</label>
                                </transition>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-bullhorn mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                <transition name="slide-fade">
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.bultenler.liste.pasif")}}</label>
                                </transition>
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </el-col>
                <el-col :lg="8" :md="8" :sm="24" :xs="24">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <!-- <li class="detay">
                                <i class="mdi mdi-account"></i> Oluşturan
                            </li> -->
                            <li class="goruntule">
                                <i class="mdi mdi-redo-variant"></i> {{$t("src.views.apps.bultenler.liste.goruntule")}}
                            </li>
                            <li class="duzenle">
                                <i class="mdi mdi-pen"></i> {{$t("src.views.apps.bultenler.liste.duzenle")}}
                            </li>
                            <li v-if="selectIcon === '1'" class="sil">
                                <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.bultenler.liste.sil")}}
                            </li>
                            <li v-else class="aktif">
                                <i class="mdi mdi-play"></i> {{$t("src.views.apps.bultenler.liste.yayinaAl")}}
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>
            <el-table :data="haberlerList.filter(data => !search.baslik || data.baslik.toLowerCase().includes(search.baslik.toLowerCase()) )"
            ref="filterTable" border stripe style="width: 100%">
                <el-table-column
                    :label='$t("src.views.apps.genel.sira")'
                    type="index"
                    :index="indexMethod"
                    width="50">
                </el-table-column>
                <el-table-column
                :label='$t("src.views.apps.bultenler.liste.table.bultenID")'
                prop="bultenID"
                width="90">
                </el-table-column>
                <!-- <el-table-column
                :label='$t("src.views.apps.bultenler.liste.table.resim")'
                width="100">
                    <template slot-scope="scope">
                        <el-popover transition="el-fade-in-linear" placement="left" width="auto" trigger="hover">
                            <img v-if="scope.row.resim" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <i style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column> -->
                <el-table-column :label='$t("src.views.apps.urun.liste.table.image")'  width="100" align="center">
                    <template slot-scope="scope">
                        <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                            <img style="max-height: 600px" v-if="scope.row.resim" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <span v-else>Resim Yok.</span>
                            <img v-if="scope.row.resim" style="max-height: 50px !important" slot="reference" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="baslik"
                    :label='$t("src.views.apps.bultenler.liste.table.baslik")'
                    width="auto">
                </el-table-column>
                <el-table-column
                    sortable
                    :label='$t("src.views.apps.bultenler.liste.table.yayinTarihi")'
                    width="130">
                        <template slot-scope="prop">
                            <div>{{prop.row.baslamaTarihi}}</div>
                            <div>{{prop.row.baslamaSaati}}</div>
                        </template>
                </el-table-column>
                <el-table-column
                    :label='$t("src.views.apps.genel.islem")'
                    align="right"
                    width="200">
                    <!-- <template slot="header" slot-scope="scope">
                        <input
                        class="searchClass"
                        v-model="search.baslik"
                        @input="forceUpper($event, search, 'baslik')"
                        size="mini"
                        placeholder="Ara"/>
                    </template> -->
                    <template slot-scope="scope">
                        <el-tooltip content='Bülten Görüntüle' :open-delay="500" placement="bottom">
                            <el-button v-on:click="yeniSekmedeAc(path+scope.row.link)" type="text" class="ml-15" style="color: #606266" icon="mdi mdi-redo-variant mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip content='Bülten Düzenle' :open-delay="500" placement="bottom">
                            <el-button
                                v-on:click="routeBulten('Bülten Düzenle',scope.row)"
                                type="text"
                                style="color: #f7ba2a; margin-left: 25px !important"
                                icon="mdi mdi-pen mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.bultenler.liste.yayindanKaldir")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='1'"
                                @click="bultenDurumDegis(scope.row, 0)"
                                size="mini"
                                class="buttonDel"
                                style="margin-left: 15px !important"
                                type="text"
                                icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayinaAl")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='0'"
                                @click="bultenDurumDegis(scope.row, 1)"
                                type="text"
                                class="buttonAktif"
                                style="margin-left: 15px !important"
                                icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import bultenService from '../../../WSProvider/BultenService'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    var moment = require('moment');
    export default {
        name: "BultenListe",
        mounted() {
            let self = this;
            
            this.selectIcon = '1',
            EventBus.$on('bultenListe', function (bool) {
                if (bool) {
                    self.page = 1;
                    self.postPage = 0;
                    self.getHaberList();
                    self.getHaberCount();
                }
            });

            this.getHaberList()
            this.getHaberCount();
        },
        data() {
            return {
                editable: [],
                selectIcon: '1',
                radio: '1',
                path: bultenService.path,
               // path: "http://89.252.181.6/vela/",
                search: {
                    baslik: ''
                },
                imagepath: bultenService.imagePath,
                page: 0,
                sizes: 10,
                postPage: 0,
                total: 0,
                haberlerList: [],
                loading: false,
                loadingState: false,
            }
        },
        methods: {
            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.getHaberList()
                this.getHaberCount();
            },

            yeniSekmedeAc(link){
                window.open(link)
            },

            handleChange(event) {
                this.page = 1;
                this.selectIcon = event;
                this.postPage = 0;
                this.getHaberList()
                this.getHaberCount();
            },

            forceUpper(e, o, prop) {
                const start = e.target.selectionStart;
                e.target.value = e.target.value.toUpperCase();
                this.$set(o, prop, e.target.value);
                e.target.setSelectionRange(start, start);
            },

            filterTag(value, row) {
                return row.kategoriKodu === value;
            },
            getHaberList() {
                try{
                    this.loading = true;
                    bultenService.bultenListe(this.selectIcon, this.postPage, this.sizes).then((response) => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token)
                            response.data.forEach(function (e) {
                                if(e.basTarih){
                                    e.baslamaSaati = moment(e.basTarih).format('HH:mm:ss');
                                    e.baslamaTarihi = moment(e.basTarih).format('DD-MM-YYYY');
                                }
                                e.bitisSaati = moment(e.bitisTarih).format('HH:mm:ss');
                                e.bitisTarihi = moment(e.bitisTarih).format('DD-MM-YYYY');
                            })
                            this.haberlerList = response.data
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.haberlerList = [];
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    notification.Status("danger", this, "Bülten listeleme sırasında bir hata meydana geldi!")
                    this.loading = false;
                }
            },
            getHaberCount() {
                try{
                    bultenService.bultenDurumSayisi(this.selectIcon).then((response) => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token)
                            this.total = response.data
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0;
                            }
                        }else{
                            notification.Status(503, this)
                        }
                    })
                }catch(e){
                }
            },

            bultenDurumDegis(selection, durum) {
                try{
                    this.loadingState = true;
                    bultenService.bultenDurumDegis(selection.bultenID, durum).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token)
                            this.getHaberList()
                            this.getHaberCount();
                        }
                        this.loadingState = false;
                        notification.Status("success", this, response.msg)
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.loadingState = false;
                    })
                }catch(e){
                    this.loadingState = false;
                }
            },

            routeBulten(name, scope) {
                if (scope) {
                    this.$store.commit('changeBultenData', scope);
                }
                this.routeSayfa(name)
            },

            handleSizeChange(val) {
                this.sizes = val;
                 this.getHaberList()
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                 this.getHaberList()
            },

            indexMethod(index){
                return ((this.page - 1)* 10) + index + 1;
            },
        }
    }

</script>

<style lang="scss">
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>

<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .el-button--mini {
        padding: 0px 15px !important;
        height: 25px !important;
    }

    .tabloFirstChild {
        position: fixed;
    }
</style>
